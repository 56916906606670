import 'bootstrap';
import hljs from 'highlight.js';
import * as commonmark from 'commonmark';

/* global $ */
$(document).on('click', '.js-paste-clear', (e) => {
  e.preventDefault();
  $('.js-paste-paste')
    .val('')
    .focus();
});

function loadLanguageItems () {
  const $languageSelectBox = $('#paste_language');
  if ($languageSelectBox.length === 0) return;

  for (const language of hljs.listLanguages().sort()) {
    $languageSelectBox.append($(
      '<option />',
      { text: language, value: language }
    ));
  }
}

function markdownfy (plaintext) {
  const reader = new commonmark.Parser();
  const writer = new commonmark.HtmlRenderer({ safe: true });

  return writer.render(reader.parse(plaintext));
}

function pasteText () {
  return $('.js-paste-paste').val() || $('.js-paste-pl').text();
}

function highlightText () {
  const $hlBox = $('.js-paste-hl');
  if ($hlBox.attr('data-processed') === '1') return;

  $hlBox.text(pasteText());

  const highlight = () => hljs.highlightBlock($hlBox[0]);
  window.setTimeout(highlight, 0);

  $hlBox.attr('data-processed', '1');
}

function markdownText () {
  const $mdBox = $('.js-paste-md');
  if ($mdBox.attr('data-processed') === '1') return;

  $mdBox.html(markdownfy(pasteText()));
  $mdBox.find('a').attr('rel', 'nofollow');
  $mdBox.attr('data-processed', '1');
}

function setHash (e) {
  let newLocation = `#${e.currentTarget.getAttribute('data-mode')}`;
  if (newLocation === '#pl') {
    newLocation = window.location.pathname;
  }

  window.history.replaceState(null, '', newLocation);
}

function showPreview (e) {
  e.preventDefault();
  const text = pasteText();
  if (text === '') return;
  $('.js-paste-preview-md-box').html(markdownfy(text));
  $('.js-paste-preview-md-modal').modal('show');
}

function switchToCurrentHash () {
  if ($('.js-showing-paste').length === 0) return;

  const format = window.location.hash.slice(1);

  if (!['pl', 'hl', 'md'].includes(format)) return;

  $('.js-show-tab[data-mode=#{format}]').click();
}

$(loadLanguageItems);
$(switchToCurrentHash);
$(document).on('click', '.js-paste-preview-md', showPreview);
$(document).on('click', '.js-show-tab[data-mode=hl]', highlightText);
$(document).on('click', '.js-show-tab[data-mode=md]', markdownText);
$(document).on('click', '.js-show-tab', setHash);
